<template>
  <div style="height: 100%">
    <v-main style="height: 100%">
      <v-container fluid class="fill-height customBack">
        <v-row align="center" justify="center" style="margin: auto">
          <v-col cols="12" md="6">
            <v-card class="elevation-12" tile>
              <v-card-title :class="headerColor" class="pa-5 text-center">
                <v-img :src="mainLogo" contain height="150px" />
              </v-card-title>
              <v-card-text>
                <router-view class="pa-3" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer app="app" color="#2A6B6E">
      <v-flex class="text-center">
        <a href="https://prianti.mx/">
          <v-img
            :src="require('@/assets/branding/poweredby_b.png')"
            contain
            height="32px"
          />
        </a>
      </v-flex>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Auth",
  data: () => ({
    headerColor: "accent",
    mainLogo: require("@/assets/branding/logo.svg"), // Este es el logo que aparece en la barra lateral
    user: {
      // email: 'admin@example.com',
      // password: 'admin',
      // name: 'John Doe',
    },
    options: {
      isLoggingIn: true,
      shouldStayLoggedIn: true,
    },
  }),
};
</script>

<style scoped>
.customBack {
  background: rgb(46, 145, 149);
  background: linear-gradient(
    to bottom,
    rgba(46, 145, 149, 1) 0%,
    rgba(41, 106, 109, 1) 100%
  );
}
</style>
